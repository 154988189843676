import React from 'react'
import { Grid, Container } from 'semantic-ui-react'
import VerticalMenu from './Menu'

export default class Layout extends React.Component {
  constructor(props) {
      super(props);
  }

  render() {
    return (
        <Container style={{marginTop: "100px"}}>
            <Grid>
                <Grid.Column width={3}>
                    <VerticalMenu tab={this.props.tab}/>
                </Grid.Column>

                <Grid.Column stretched width={10}>
                    {this.props.children}
                </Grid.Column>
            </Grid>
        </Container>
    )
  }
}
