import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react'
import { navigate } from "gatsby"

export default class VerticalMenu extends Component {
    state = { activeItem: 'blog' }

    handleItemClick = (e, { name }) => {
        this.setState({ activeItem: name });
        navigate(`/${name.replace(' ', '')}`);
    }

    componentDidMount() {
        if (this.props.tab) {
            this.setState({ activeItem: this.props.tab });
        }
    }

    render() {
        const { activeItem } = this.state

        return (
            <Menu pointing secondary vertical fluid>
                {['blog', 'projects', 'about me', 'other'].map(el => 
                    <Menu.Item
                        name={el}
                        active={activeItem === el}
                        onClick={this.handleItemClick}
                    />
                )}
            </Menu>
        )
    }
}
